import axios from 'axios'
import Pusher from 'pusher-js/worker'
// import Echo from 'laravel-echo'

axios.defaults.withCredentials = true

// Intercept axios 401 responses
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      window.location = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

window.axios = axios

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
window.Pusher = Pusher

/*window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  wsHost: window.location.hostname,
  wsPort: import.meta.env.VITE_ECHO_WS_PORT ?? 80,
  wssPort: import.meta.env.VITE_ECHO_WSS_PORT ?? 443,
  disableStats: true,
  enabledTransports: ['ws'],
})*/
